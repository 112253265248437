<template>
  <div class="container">
    <div class="col-12">
      <div class="text-left my-3">あなたは研究対象者に選ばれました。<br>登録時の視聴した説明動画をもう一度視聴することができます</div>
      <YoutubeComponent />
      <div class="text-left mt-4">・既に同意された内容により、採取セットをご自宅に送付いたしますが、<br>研究への参加をされますか？</div>
      <div class="ipcheck mt-4" ref="accept">
        <div class="checkbox">
          <label for="accept"><input type="radio" name="select" class="option-input02 option-input02-left2 checkbox" id="accept" @change="viewSampleCollectionDays('1')" />はい&nbsp;研究に参加します<br>→&nbsp;試料採取予定日および試料回収希望日時を以下のカレンダーに記載して下さい。<br><br>なお、2種類の試料採取（8分程度かかります）は早朝起きた際に行って頂きますが、試料採取の12時間前（5時に起床する場合は、夕方5時）からお酒、カフェインを含む飲料（コーヒー、紅茶、緑茶、栄養ドリンク・エナジードリンク等）、ニコチンは控えて頂きます。ご協力下さい。</label>
        </div>
        <div class="checkbox">
          <label for="decline"><input type="radio" name="select" class="option-input02 option-input02-left2 checkbox" id="decline" @change="viewSampleCollectionDays('0')" />いいえ&nbsp;研究に参加しません<br>→&nbsp;同意を撤回することによってあなたに一切の不利益はありません。応募時の入力情報は速やかに破棄し、研究に利用致しません。</label>
        </div>
      </div>
    </div>
  </div>
  <div v-if="viewFlg" class="container mb-5">
    <div class="col-12">
      <div class="text-left my-3">①採取予定日を選択してください。</div>
      <select v-model="research.formData.samplingDate" class="form-control" id="inputSex" required @change="selectSamplingDate" ref="sampling">
        <option value="" selected>選択してください</option>
        <option v-for="sampleDay in research.sampleDays" v-bind:key="sampleDay" :value="getFormatDateId(sampleDay)">{{ getFormatDate(sampleDay) }}</option>
      </select>
    </div>
  </div>
  <div v-if="viewFlg" class="container">
    <div class="col-12">
      <div class="text-left my-3">②試料回収希望日時を入力してください。<br><span class="font-weight-bold">※回収時間を調整するため、可能な時間をできるだけ複数チェックしてください。</span></div>
    </div>
  </div>
  <div v-if="viewFlg" class="calendar ipcheck w-100 position-sm-relative position-absolute" style="left:0">
    <div class="table table-striped bordered" ref="table">
      <div class="d-table table-head w-100 overflow-hidden" contenteditable='true' ref="tableheader">
        <div scope="col" class="d-table-cell p-1 col-2" style="min-width: 50px"></div>
        <div v-for="time in research.times" v-bind:key="time" scope="col" class="d-table-cell th text-center p-1 col-2" style="min-width: 50px">{{ time[0] }}～{{ time[1] }}</div>
      </div>
      <div v-for="day in research.days" v-bind:key="day" class="d-table w-100 table-body overflow-hidden">
        <label :for="getFormatDateId(day)" class="d-table-cell th align-middle text-center p-1 col-2" scope="row">
          <div class="pb-1">{{ getFormatDate(day) }}</div>
          <input :id="getFormatDateId(day)" type="checkbox" class="option-input align-middle checkbox" @change="checkAll" />
        </label>
        <label  v-for="time in research.times" v-bind:key="time" :for="getFormatDateId(day, time)" class="d-table-cell td align-middle text-center p-1 col-2">
          <input :id="getFormatDateId(day, time)" type="checkbox" class="option-input align-middle checkbox time" @change="checkOne" />
        </label>
      </div>
    </div>
  </div>
  <div ref="blank" v-show="viewFlg" />
  <ul class="nav mt-3">
    <li><button type="button" class="btn_next btn btn-primary" @click="confirmAction">次へ</button></li>
  </ul>
</template>
<script>
import { Mixin } from '@/mixin.js'
import YoutubeComponent from '@/components/Youtube'
import { sleep } from '@/utils/common.js';
import { getMemberCode } from '@/utils/common.js';
import { getActivateFlg } from '@/api/member.js';
import { CONST } from '@/constants.js';

export default {
  components: { YoutubeComponent },
  mixins: [Mixin],
  data() {
    return {
      viewFlg: true,
    }
  },
  async created() {
    const memberCode = getMemberCode()
    const activate_flg = await getActivateFlg(memberCode)

    switch(activate_flg.value) {
      case CONST.MEMBER.ACTIVATE_FLG.RESEARCH_SUBJECT:
        break
      case CONST.MEMBER.ACTIVATE_FLG.PRODUCTION_AGREE:
        this.$router.push({name: 'Explain'})
        break
      case CONST.MEMBER.ACTIVATE_FLG.PRODUCTION_COMPLETED:
        this.$router.push({name: 'ProductionComplete'})
        break
      default:
        this.$router.push({name: 'LoginPage'})
        break
    }
  },
  async mounted() {
    this.research.days = this.getCandidateDates(this.research.startDate, this.research.endDate)
    let sampleStartDate = this.chars8ToDate(this.research.sampleStartDate)
    const now = new Date()
    if (sampleStartDate <= now) {
      sampleStartDate = new Date(now.setDate(now.getDate() + 1))
    }
    this.research.sampleDays = this.getCandidateDates(this.dateTochars8(sampleStartDate), this.research.sampleEndDate)
    window.addEventListener('scroll', this.scrollEvent)
    window.addEventListener('resize', this.resizeEvent)

    let accept = localStorage.getItem('accept')
    const sampleDay = localStorage.getItem('sampleDay')
    const collectionDays = localStorage.getItem('collectionDays')
    if (accept === null) {
      localStorage.setItem('accept', '1')
      accept = '1'
    }
    document.getElementById(accept === '1' ? 'accept' : 'decline').checked = true
    this.viewSampleCollectionDays(accept)
    if (!this.research.sampleExpireFlg && accept === '1' && sampleDay && collectionDays) {
      await sleep(0)
      this.$refs.sampling.value = this.getFormatDateId(this.chars8ToDate(sampleDay))
      this.selectSamplingDate({target: this.$refs.sampling})
      this.$refs.table.querySelectorAll('input.time').forEach(elem => {
        if (collectionDays.indexOf(elem.id) !== -1) {
          elem.checked = true
        }
      })
      this.$refs.table.querySelectorAll('.th input').forEach(elem => {
        elem.checked = Array.from(elem.closest('.table-body').querySelectorAll('input.time')).reduce((flg, e) => {
          if (!flg) return false
          return e.checked
        }, true)
      })
    }
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.scrollEvent)
    window.removeEventListener('resize', this.resizeEvent)
  },
  methods: {
    viewSampleCollectionDays: function(viewFlg) {
      this.viewFlg = viewFlg === '1'
      if (this.research.sampleExpireFlg) {
        this.viewFlg = false
      }
      localStorage.setItem('accept', viewFlg)
      if (!viewFlg) return

      let cnt = 0
      const this2 = this
      const interval = setInterval(() => {
        if (cnt > 100 || this2.$refs.table) {
          this2.scrollEvent()
          this2.resizeEvent()
          clearInterval(interval)
        }
        cnt++
      })
    },
    getMemberCode: function() {
      return sessionStorage.getItem('login_member_code')
    },
    checkOne: function(event) {
      const target = event.target
      const checked = target.checked
      const tableBody = target.closest('.table-body')
      const allElem = tableBody.querySelector('.th input')

      if (!checked) {
        if (allElem.checked) allElem.checked = false
      } else {
        const oneElems = tableBody.querySelectorAll('.td input')
        if (!allElem.checked) {
          allElem.checked = Array.from(oneElems).reduce((checked, elem) => {
            if (!checked) return false
            return elem.checked
          }, true)
        }
      }
    },
    checkAll: function(event) {
      const target = event.target
      const checked = target.checked
      target.closest('.table-body').querySelectorAll('.td input').forEach(elem => {
        elem.checked = checked
      })
    },
    selectSamplingDate: function(e) {
      const samplingDate = e.target.value
      const table = this.$refs.table
      table.querySelectorAll('input').forEach(elem => {
        elem.disabled = parseInt(elem.id.slice(0, 8)) < parseInt(samplingDate)
        if (elem.disabled) elem.checked = false
      })
    },
    /**
     * 同意しているかチェック
     */
    checkAccept: function() {
      let status = false
      this.$refs.accept.querySelectorAll('input[name="select"]').forEach(elem => {
        if (elem.checked) {
          status = elem.id
          return false
        }
      })
      return status
    },
    confirmAction: function() {
      const checkAccept = this.checkAccept()
      if (checkAccept === 'accept') {
        if (!this.research.sampleExpireFlg) {
          const sampleDay = this.$refs.sampling.value
          const errors = []
          if (sampleDay === '') {
            errors.push('採取予定日が未選択です。')
          }
          const schedules = this.getCheckedSchedules()
          if (!schedules.length) {
            errors.push('試料回収希望日時が未選択です。')
          }
          if (errors.length) {
            alert(errors.join("\n"))
            return
          }

          localStorage.setItem('sampleDay', sampleDay)
          localStorage.setItem('collectionDays', JSON.stringify(schedules))
        }
      } else if (checkAccept !== 'decline') {
        alert('研究の参加有無をチェックしてください。')
        return
      }

      // 会員登録画面へ
      this.$router.push({name: 'ResearchConfirm'})
    },
    getCheckedSchedules: function() {
      if (this.$refs.table) {
        return Array.from(this.$refs.table.querySelectorAll('input.time')).reduce((days, elem) => {
          if (elem.checked && !elem.disabled) days.push(elem.id)
          return days
        }, [])
      }
      return []
    },
    scrollEvent: function() {
      const tableheader = this.$refs.tableheader
      if (!tableheader) return
      const navbarHeight = document.querySelector('nav.navbar').offsetHeight
      const tableheaderClientRect = tableheader.getBoundingClientRect()
      const tableClientRect = this.$refs.table.getBoundingClientRect()
      const contains = tableheader.classList.contains('fixed')

      if (contains) {
        if (tableClientRect.y > navbarHeight + tableheader.offsetHeight) {
          tableheader.classList.remove('fixed')
        }
      } else {
        if (tableheaderClientRect.y <= navbarHeight) {
          tableheader.classList.add('fixed')
        }
      }
    },
    resizeEvent: function() {
      const table = this.$refs.table
      const blank = this.$refs.blank
      if (!table || !blank) return
      if (window.innerWidth >= 576) {
        blank.style.height = 0
      } else {
        blank.style.height = (table.offsetHeight + 30) + 'px'
      }
    },
  },
}
</script>
<style src="../assets/css/pages/questionnaire.css" scoped></style>
